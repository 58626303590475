<template>
  <div v-if="privateQrCode">
    <img :src="privateQrCode">
  </div>
</template>

<script setup lang="ts">
import QRCode from 'qrcode'

const props = defineProps({
  qrValue: {
    type: String,
    default: ''
  },
  qrCode: {
    type: String,
    default: null
  },
  size: {
    type: Number,
    default: 120,
  },
})

const { qrValue, qrCode, size } = toRefs(props)

const privateQrCode = ref(qrCode.value)

const setupQRCode = async () => {
  try {
    privateQrCode.value = await QRCode.toDataURL(qrValue.value, { scale: size.value })
  } catch {}
}

watch(qrValue, async (newValue) => {
  if (!!newValue) {
    setupQRCode()
  }
})

onMounted(() => {
  if (!qrCode.value) {
    setupQRCode()
  }
})
</script>

